import classnames from 'classnames';
import React, {useContext} from 'react';
import {BLOCK_ANIMATION} from '../../constants/blocks';
import {EVOLUTION_TYPE_TOUR} from '../../constants/evolution';
import {PokeStateContext} from '../../context';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';
import {AnimationCheck} from './check';
import {AnimationConfetti, WindowAnimationConfetti} from './confetti';
import {AnimationHeart} from './heart';
import {AnimationSmiley} from './smiley';

export const ANIMATION_TYPE_CONFETTI = 'confetti';
export const ANIMATION_TYPE_HEART = 'heart';
export const ANIMATION_TYPE_CHECK = 'check';
export const ANIMATION_TYPE_SMILEY = 'smiley';

export const parseAnimationValue = (value) => {
  const [type] = value.split(';');

  return {
    type,
  };
};

export const BlockAnimation = () => {
  const {currentStep, experienceType, inBuilder} = useContext(PokeStateContext);

  const isTour = experienceType === EVOLUTION_TYPE_TOUR;

  const block = currentStep.blocks.find((b) => b.type === BLOCK_ANIMATION);
  const value = parseAnimationValue(block.value);

  return (
    <div
      className={classnames('poke-block-animation', {
        'type-heart': value.type === ANIMATION_TYPE_HEART,
        'type-smiley': value.type === ANIMATION_TYPE_SMILEY,
        'type-check': value.type === ANIMATION_TYPE_CHECK,
        'is-window-size': isTour === true,
      })}>
      {isTour === true && inBuilder !== true && (
        <WindowAnimationConfetti speed={block?.style?.speed || 100} />
      )}
      {isTour !== true && (
        <>
          {value.type === ANIMATION_TYPE_CONFETTI && <AnimationConfetti />}
          {value.type === ANIMATION_TYPE_CHECK && <AnimationCheck />}
          {value.type === ANIMATION_TYPE_SMILEY && <AnimationSmiley />}
          {value.type === ANIMATION_TYPE_HEART && <AnimationHeart />}
          <ClickableBlockOverlay />
        </>
      )}
    </div>
  );
};
