import {MEDIA_POSITION_TOP} from '../components/BlockMedia';
import {
  BLOCK_MEDIA,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_STEPPER,
  BLOCK_USER,
} from '../constants/blocks';

export const hasLayoutTop = (blocks = []) => {
  const blockMedia = blocks.find((b) => b.type === BLOCK_MEDIA);
  const shouldShowBlockMedia =
    blockMedia != null && blockMedia?.style?.position === MEDIA_POSITION_TOP;
  const shouldShowBlockUser =
    blocks.findIndex((b) => b.type === BLOCK_USER) >= 0;
  const blockStepper = blocks.find((b) => b.type === BLOCK_STEPPER);
  const shouldShowBlockStepper =
    blockStepper != null && blockStepper.style.position === 'top';

  return shouldShowBlockMedia || shouldShowBlockUser || shouldShowBlockStepper;
};

export const hasLayoutBottom = (blocks = []) => {
  const shouldShowBlockCta = blocks.some(
    (b) =>
      [BLOCK_PRIMARY_CTA, BLOCK_SECONDARY_CTA].includes(b.type) &&
      b.removed !== true
  );
  const blockStepper = blocks.find((b) => b.type === BLOCK_STEPPER);
  const shouldShowBlockStepper =
    blockStepper != null && blockStepper.style.position === 'bottom';

  return shouldShowBlockCta || shouldShowBlockStepper;
};
