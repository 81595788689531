import React, {useContext} from 'react';
import {
  BLOCK_BODY,
  BLOCK_DISMISS_CROSS,
  BLOCK_LABEL,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_TITLE,
} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {BlockBody} from '../BlockBody';
import {BlockCta} from '../BlockCta';
import {BlockDismissCross} from '../BlockDismissCross';
import {BlockLabel} from '../BlockLabel';
import {BlockTitle} from '../BlockTitle';
import './_styles.scss';

export const LayoutBanner = () => {
  const {currentStep} = useContext(PokeStateContext);

  const shouldShowBlockCta = currentStep.blocks.find((b) =>
    [BLOCK_PRIMARY_CTA, BLOCK_SECONDARY_CTA].includes(b.type)
  );

  const hasLabel =
    currentStep.blocks.find((b) => b.type === BLOCK_LABEL) != null;
  const hasTitle =
    currentStep.blocks.find((b) => b.type === BLOCK_TITLE) != null;
  const hasBody = currentStep.blocks.find((b) => b.type === BLOCK_BODY) != null;
  const hasDismissCross = currentStep.blocks?.some(
    (b) => b.type === BLOCK_DISMISS_CROSS
  );

  const {style} = currentStep;

  const {paddingTop, paddingBottom, paddingLeft, paddingRight, gap, alignment} =
    style || {};

  return (
    <div
      className="poke-layout-banner"
      style={{
        // Default padding used to be 8px 0
        paddingTop: paddingTop ?? 8,
        paddingBottom: paddingBottom ?? 8,
        paddingLeft: paddingLeft ?? 0,
        paddingRight: paddingRight ?? 0,
      }}>
      <div
        className="layout-banner-center-col"
        style={{
          gap: gap ?? 24,
          justifyContent: alignment ?? 'center',
        }}>
        <div
          className="layout-banner-center-col-content"
          style={{
            gap: gap ?? 12,
          }}>
          {(hasLabel || hasTitle) && (
            <div
              className="layout-banner-center-col-content-top"
              style={{
                gap: gap ?? 24,
                justifyContent:
                  alignment === 'space-between' ? 'left' : 'center',
              }}>
              {hasLabel && <BlockLabel />}
              {hasTitle && <BlockTitle />}
            </div>
          )}
          {hasBody && (
            <div
              className="layout-banner-center-col-content-bottom"
              style={{
                justifyContent:
                  alignment === 'space-between' ? 'left' : 'center',
              }}>
              <BlockBody />
            </div>
          )}
        </div>
        {shouldShowBlockCta != null && <BlockCta />}
      </div>
      {hasDismissCross === true && (
        <div className="layout-banner-dismiss-cross-wrapper">
          <BlockDismissCross />
        </div>
      )}
    </div>
  );
};
