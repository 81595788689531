export const BLOCK_TITLE = 'TITLE';
export const BLOCK_BODY = 'BODY';
export const BLOCK_MEDIA = 'MEDIA';
export const BLOCK_USER = 'USER';
export const BLOCK_LABEL = 'LABEL';
export const BLOCK_PRIMARY_CTA = 'PRIMARY_CTA';
export const BLOCK_SECONDARY_CTA = 'SECONDARY_CTA';
export const BLOCK_STEPPER = 'STEPPER';
export const BLOCK_CHOICE = 'CHOICE';
export const BLOCK_SLIDER = 'SLIDER';
export const BLOCK_NPS = 'NPS';
export const BLOCK_OPINION = 'OPINION';
export const BLOCK_INTERVIEW = 'INTERVIEW';
export const BLOCK_OPEN_QUESTION = 'OPEN_QUESTION';
export const BLOCK_ANIMATION = 'ANIMATION';
export const BLOCK_CONCEPT = 'CONCEPT';
export const BLOCK_JIMO_LABEL = 'JIMO_LABEL';
export const BLOCK_HINT = 'HINT';
export const BLOCK_CHECKLIST_HEADER = 'CHECKLIST_HEADER';
export const BLOCK_CHECKLIST_HEADER_TITLE = 'CHECKLIST_HEADER_TITLE';
export const BLOCK_CHECKLIST_HEADER_DESCRIPTION =
  'CHECKLIST_HEADER_DESCRIPTION';
export const BLOCK_CHECKLIST_HEADER_PROGRESS = 'CHECKLIST_HEADER_PROGRESS';
export const BLOCK_CHECKLIST_TASK_LIST = 'CHECKLIST_TASK_LIST';
export const BLOCK_CHECKLIST_TASK_ITEM = 'CHECKLIST_TASK_ITEM';
export const BLOCK_CHECKLIST_TASK_CHECKBOX = 'CHECKLIST_TASK_ITEM_CHECKBOX';
export const BLOCK_CHECKLIST_TASK_ITEM_TITLE = 'CHECKLIST_TASK_ITEM_TITLE';
export const BLOCK_CHECKLIST_TASK_ITEM_DESCRIPTION =
  'CHECKLIST_TASK_ITEM_DESCRIPTION';
export const BLOCK_CHECKLIST_TASK_ITEM_MEDIA = 'CHECKLIST_TASK_ITEM_MEDIA';
export const BLOCK_CHECKLIST_TASK_ITEM_PRIMARY_CTA =
  'CHECKLIST_TASK_ITEM_PRIMARY_CTA';
export const BLOCK_CHECKLIST_TASK_ITEM_SECONDARY_CTA =
  'CHECKLIST_TASK_ITEM_SECONDARY_CTA';
export const BLOCK_CHECKLIST_TRIGGER = 'CHECKLIST_TRIGGER';
export const BLOCK_CHECKLIST_DISMISS = 'CHECKLIST_DISMISS';
export const BLOCK_DISMISS_CROSS = 'DISMISS_CROSS';
export const BLOCK_CURSOR = 'CURSOR';
export const BLOCK_HOTSPOT = 'HOTSPOT';
export const BLOCK_VISUAL_CUE = 'VISUAL_CUE';
export const getDefaultResponseForStep = (step) => {
  const blockInteractive = step.blocks.find(
    (b) => isInteractiveBlock(b.type) === true
  );

  if (blockInteractive == null) {
    return undefined;
  }
  return getDefaultResponseForBlock(blockInteractive.type);
};

export const getDefaultResponseForBlock = (blockType) => {
  switch (blockType) {
    case BLOCK_CHOICE:
      return [];
    case BLOCK_SLIDER:
      return '';
    case BLOCK_OPEN_QUESTION:
      return '';
    case BLOCK_OPINION:
      return '';
    case BLOCK_NPS:
      return '';
    default:
      return undefined;
  }
};

export const isInteractiveBlock = (type) => {
  return [
    BLOCK_NPS,
    BLOCK_CHOICE,
    BLOCK_OPEN_QUESTION,
    BLOCK_SLIDER,
    BLOCK_OPINION,
  ].includes(type);
};
