import Confetti from 'canvas-confetti';
import {useEffect, useRef} from 'react';

export const AnimationConfetti = (props = {}) => {
  const refCanvas = useRef(null);

  useEffect(() => {
    if (refCanvas.current != null) {
      const confetti = Confetti.create(refCanvas.current, {resize: true});

      confetti({
        origin: {y: 0.5},
        particleCount: 50,
        zIndex: 1,
        spread: 100,
        ticks: 500,
        startVelocity: 20,
        ...props,
      });
    }
  }, [refCanvas, props]);

  return <canvas style={{width: '100%', height: '100%'}} ref={refCanvas} />;
};

export const WindowAnimationConfetti = ({speed = 100}) => {
  useEffect(() => {
    playConfettiAnimation(speed);
  }, [speed]);

  return null;
};

export const playConfettiAnimation = (speed = 100) => {
  const count = 200;
  const defaults = {
    origin: {y: 0.7},
    gravity: speed / 100,
  };

  function fire(particleRatio, opts) {
    Confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
    });
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
    ticks: (200 / speed) * 100,
  });
  fire(0.2, {
    spread: 60,
    ticks: (200 / speed) * 100,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
    ticks: (200 / speed) * 100,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
    ticks: (200 / speed) * 100,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
    ticks: (200 / speed) * 100,
  });
};
