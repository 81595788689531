import classNames from 'classnames';
import React, {useContext} from 'react';
import {
  BLOCK_BODY,
  BLOCK_CHOICE,
  BLOCK_CONCEPT,
  BLOCK_INTERVIEW,
  BLOCK_LABEL,
  BLOCK_MEDIA,
  BLOCK_NPS,
  BLOCK_OPEN_QUESTION,
  BLOCK_OPINION,
  BLOCK_SLIDER,
  BLOCK_TITLE,
} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {BlockStep} from '../BlockStep';
import './_styles.scss';
import {hasLayoutBottom, hasLayoutTop} from '../../utils/layout';

const LayoutMiddle = () => {
  const {currentStep, hasCustomHeight, isHint} = useContext(PokeStateContext);

  const hasBlockLabel = currentStep.blocks.some((b) => b.type === BLOCK_LABEL);
  const hasBlockTitle = currentStep.blocks.some((b) => b.type === BLOCK_TITLE);
  const hasBlockBody = currentStep.blocks.some((b) => b.type === BLOCK_BODY);
  const hasBlockMedia = currentStep.blocks.some((b) => b.type === BLOCK_MEDIA);
  const hasBlockNPS = currentStep.blocks.some((b) => b.type === BLOCK_NPS);
  const hasBlockSlider = currentStep.blocks.some(
    (b) => b.type === BLOCK_SLIDER
  );
  const hasBlockOpenQuestion = currentStep.blocks.some(
    (b) => b.type === BLOCK_OPEN_QUESTION
  );
  const hasBlockOpinion = currentStep.blocks.some(
    (b) => b.type === BLOCK_OPINION
  );
  const hasBlockInterview = currentStep.blocks.some(
    (b) => b.type === BLOCK_INTERVIEW
  );
  const hasBlockChoice = currentStep.blocks.some(
    (b) => b.type === BLOCK_CHOICE
  );
  const hasBlockConcept = currentStep.blocks.some(
    (b) => b.type === BLOCK_CONCEPT
  );

  const hasLayoutTopValue = hasLayoutTop(currentStep.blocks);
  const hasLayoutBottomValue = hasLayoutBottom(currentStep.blocks);

  if (
    hasBlockLabel !== true &&
    hasBlockTitle !== true &&
    hasBlockBody !== true &&
    hasBlockMedia !== true &&
    hasBlockNPS !== true &&
    hasBlockSlider !== true &&
    hasBlockOpenQuestion !== true &&
    hasBlockOpinion !== true &&
    hasBlockInterview !== true &&
    hasBlockChoice !== true &&
    hasBlockConcept !== true
  ) {
    return <></>;
  }

  return (
    <div
      className={classNames('poke-layout-middle', {
        'has-custom-height': hasCustomHeight === true,
      })}
      style={{
        ...(hasCustomHeight === true
          ? {
              ...(hasLayoutTopValue !== true
                ? {
                    paddingTop: `${
                      currentStep?.style?.paddingTop ?? (isHint ? 16 : 24)
                    }px`,
                  }
                : {}),
              ...(hasLayoutBottomValue !== true
                ? {
                    paddingBottom: `${
                      currentStep?.style?.paddingBottom ?? (isHint ? 16 : 24)
                    }px`,
                  }
                : {}),
              paddingLeft: `${
                currentStep?.style?.paddingLeft ?? (isHint ? 16 : 24)
              }px`,
              paddingRight: `${
                currentStep?.style?.paddingRight ?? (isHint ? 16 : 24)
              }px`,
            }
          : {}),
      }}>
      <BlockStep />
    </div>
  );
};

export default LayoutMiddle;
