import classNames from 'classnames';
import React, {useContext} from 'react';
import {
  BLOCK_ANIMATION,
  BLOCK_BODY,
  BLOCK_DISMISS_CROSS,
  BLOCK_JIMO_LABEL,
  BLOCK_MEDIA,
  BLOCK_USER,
} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {useBackgroundContrastedColor} from '../../hooks/useBackgroundContrastedColor';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import {hex2Rgb} from '../../utils/hex-2-rgb';
import {BlockAnimation} from '../BlockAnimation';
import {BlockBody} from '../BlockBody';
import {BlockDismissCross} from '../BlockDismissCross';
import {BlockMedia} from '../BlockMedia';
import {BlockUserCursor} from '../BlockUser';
import './_styles.scss';

export const LayoutCursor = ({JimoLabel}) => {
  const {poke, currentStep, disableBlockAnimation} =
    useContext(PokeStateContext);

  const contrastedColor = useBackgroundContrastedColor('bottom');
  const playJimoLabelAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_JIMO_LABEL,
  });

  const {style} = poke;
  const {background} = style ?? {};

  const hasProfile =
    currentStep.blocks.find((b) => b.type === BLOCK_USER) != null;
  const hasBody = currentStep.blocks.find((b) => b.type === BLOCK_BODY) != null;
  const hasDismissCross = currentStep.blocks?.some(
    (b) => b.type === BLOCK_DISMISS_CROSS
  );
  const hasMedia = currentStep.blocks?.some((b) => b.type === BLOCK_MEDIA);
  const hasAnimation =
    disableBlockAnimation !== true &&
    currentStep.blocks?.findIndex((b) => b.type === BLOCK_ANIMATION) >= 0;

  return (
    <div className="poke-layout-cursor-wrapper">
      <div
        className="poke-layout-cursor"
        style={{
          paddingTop: `${currentStep?.style?.paddingTop ?? 12}px`,
          paddingBottom: `${currentStep?.style?.paddingBottom ?? 12}px`,
          paddingLeft: `${currentStep?.style?.paddingLeft ?? 12}px`,
          paddingRight: `${currentStep?.style?.paddingRight ?? 12}px`,
          gap: `${currentStep?.style?.gap ?? 12}px`,
        }}>
        <div
          className="layout-cursor-content"
          style={{
            gap: `${currentStep?.style?.gap ?? 12}px`,
          }}>
          {hasProfile && <BlockUserCursor />}
          {hasBody && (
            <div className="layout-banner-center-col-content-bottom">
              <BlockBody />
            </div>
          )}
          {hasDismissCross === true && (
            <div className="layout-cursor-dismiss-cross">
              <BlockDismissCross />
            </div>
          )}
        </div>
        {hasMedia && (
          <div className="layout-cursor-media">
            <BlockMedia isCursor />
          </div>
        )}
      </div>
      {JimoLabel != null && (
        <div
          className={classNames('jimo-label-wrapper', {
            'is-animating-out': playJimoLabelAnimationOut === true,
          })}
          style={{
            borderTopColor: `rgba(${hex2Rgb(contrastedColor).join(',')},${
              contrastedColor === '#000000' ? '0.05' : '0.2'
            })`,
            color: contrastedColor,
          }}>
          <JimoLabel background={background} />
        </div>
      )}

      {hasAnimation === true && <BlockAnimation />}
    </div>
  );
};
